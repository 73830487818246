import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import { Product } from "../types/product"
import styles from "./download.module.css"
import productsJSON from "../data/products.json"
import { downloadProduct } from "../utils"

const DownloadTemplate: React.FC<{
  pageContext: {
    productId: Product["id"]
    productKey: Product["id"]
  }
}> = ({ pageContext: { productId } }) => {
  const product = productsJSON.products.find(item => item.id === productId)!
  const onDownloadClick = (e: React.MouseEvent) => {
    e.preventDefault()
    downloadProduct(product)
  }
  return (
    <Layout>
      <SEO
        meta={[
          {
            name: "robots",
            content: "noindex",
          },
        ]}
        title={`Download ${product.name}`}
      />
      <article className={styles.box}>
        <div className={styles.content}>
          <h2>Thank you for purchase! 💚</h2>
          <p>
            We hope you'll enjoy it! Feel free to contact us by this email:
            hello@palapa.tech if you'll have any questions
          </p>
          <p>Have a nice day! :)</p>
          <div className={styles.downloadBtnWrap}>
            <a
              href="#"
              onClick={onDownloadClick}
              className={styles.downloadBtn}
              target="_blank"
            >
              Download your product
            </a>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default DownloadTemplate
